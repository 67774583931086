<template>
  <div id="home">

    <el-row :gutter="20">
      <el-col :xs="24" :sm="8" :md="6">
        <el-card class="box-card menu">
          <h2 style="margin-top: 0">
              <span>Filters</span>
              <el-button class="hidden-sm-and-up" v-if="showFilters" style="float:right;" size="medium" round @click="showFilters = false">Hide</el-button>
              <el-button class="hidden-sm-and-up" v-else style="float:right;" size="medium" round @click="showFilters = true">Show</el-button>
          </h2>

          <el-input
            placeholder="Search by ID"
            v-model="search"
            @input="handleFilterChange"
          >
            <i slot="prefix" class="el-input__icon fas fa-search"></i>
          </el-input>
          <el-select v-model="sort" @change="handleFilterChange" style="display: block; margin-top: 14px;">
            <el-option
              v-for="opt in sortOpts"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value">
            </el-option>
          </el-select>

          <el-collapse v-show="showFilters" v-model="activeFilters" style="margin-top: 14px;">
            <el-collapse-item title="For Sale" name="For Sale">
              <el-checkbox-group v-model="tokenFilters['For Sale']" @change="handleFilterChange">
                <el-checkbox label="Fixed Price" style="display:block;">
                  Fixed Price
                  <el-tag type="info" size="mini" effect="dark" style="float:right; border-radius: 12px;">{{ activeAsks.length }}</el-tag>
                </el-checkbox>
                <el-checkbox label="English Auction" style="display:block;">
                  Highest Bid Auction
                  <el-tag type="info" size="mini" effect="dark" style="float:right; border-radius: 12px;">{{ activeEnglishAuctions.length }}</el-tag>
                </el-checkbox>
                <!-- <el-checkbox label="Dutch Auction" style="display:block;">
                  Dutch Auction
                  <el-tag type="info" size="mini" effect="plain" style="float:right; border-radius: 12px;">{{ activeDutchAuctions.length }}</el-tag>
                </el-checkbox> -->
              </el-checkbox-group>
            </el-collapse-item>

            <el-collapse-item :title="traitCategory" :name="traitCategory" v-for="(values, traitCategory) in traits" :key="traitCategory">
              <el-checkbox-group v-model="tokenFilters[traitCategory]" @change="handleFilterChange">
                <el-checkbox v-for="(traitCount, trait) in values" :key="`${traitCategory}-${trait}`" :label="trait" style="display:block;">
                  {{ trait }}
                  <el-tag size="mini" effect="dark" style="float:right; border-radius: 12px;">{{ (traitCount / 3000 * 100).toFixed(2) }}%</el-tag>
                  <el-tag type="info" size="mini" effect="dark" style="float:right; border-radius: 12px; margin-right: 5px;">{{ traitCount }}</el-tag>
                </el-checkbox>
              </el-checkbox-group>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="16" :md="18" class="main">
        <div v-if="displayedTokens.length === 0">
          <el-empty description="No tokens found" image-size="325" :image="require(`@/assets/error.png`)"></el-empty>
        </div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="token in displayedTokens" :key="token.id" style="position: relative; margin-bottom: 20px;">
            <el-card shadow="hover" :body-style="{ position: 'relative', padding: '0px' }">
              <router-link :to="{ name: 'token', params: { id: token.id } }"><img :src="transformUri(token.displayUri)" style="width: 100%; display: block;" /></router-link>
              <div style="padding: 14px;">
                <el-link :href="`https://objkt.com/asset/${objktCollection}/${token.id}`" target="_blank">#{{ token.id }} <i class="far fa-external-link fa-icon-right"></i></el-link>
                <span style="float: right; font-size: 14px; margin-top: 3px;">Rank #{{ token.rank }}</span>
              </div>

              <div v-if="getFixedPrice(token.id)" :set="ask = getFixedPrice(token.id)">
                <div style="border-top: 1px solid #454545; padding: 14px; font-family: Roboto, sans-serif; font-size: 12px; height: 20px;">
                  <span><strong>Price:</strong> {{ vueNumberFormat(ask.price / 1e6) }} ꜩ</span>
                </div>
              </div>
              <div v-else-if="getEnglishAuction(token.id)" :set="auction = getEnglishAuction(token.id)">
                <div style="border-top: 1px solid #454545; padding: 14px; font-family: Roboto, sans-serif; font-size: 12px; height: 20px;">
                  <span v-if="auction.bids[0].amount > 0"><strong>High Bid:</strong> {{ vueNumberFormat(auction.bids[0].amount / 1e6) }} ꜩ</span>
                  <span v-else style="color: #909399;"><strong>Reserve:</strong> {{ vueNumberFormat(auction.reserve / 1e6) }} ꜩ</span>
                  <span style="float: right;">
                    <el-link :href="`https://objkt.com/auction/e/${auction.hash}`" target="_blank" style="font-size: 12px;">view<i class="far fa-external-link fa-icon-right"></i></el-link>
                  </span>
                </div>
              </div>
              <div v-else>
                <div style="border-top: 1px solid #454545; padding: 14px; font-family: Roboto, sans-serif; font-size: 12px; text-align: center; height: 20px; color: #C0C4CC">
                  <span>Not for sale</span>
                </div>
              </div>

              <el-popover
                placement="left-start"
                title=""
                width="325"
                trigger="hover">
                <div ref="content">
                  <el-descriptions title="Traits" :column="1" size="small" border>
                    <el-descriptions-item v-for="attribute in token.attributes" :key="`${token.id}-${attribute.name}`" :label="attribute.name">
                      {{ attribute.value }}
                      <el-tag size="mini" effect="dark" style="float:right; border-radius: 12px;">{{ (traits[attribute.name][attribute.value] / 3000 * 100).toFixed(2) }}%</el-tag>
                      <el-tag type="info" size="mini" effect="dark" style="float:right; border-radius: 12px; margin-right: 5px;">{{ traits[attribute.name][attribute.value] }}</el-tag>
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
                <i slot="reference" class="fas fa-info-circle" style="color: #fff; position: absolute; top: 8px; left: 8px;"></i>
              </el-popover>
            </el-card>

            <el-badge v-if="getFixedPrice(token.id)" value="Fixed Price" type="success" style="position: absolute; top: -6px; right: 14px;"></el-badge>
            <el-badge v-if="getEnglishAuction(token.id)" value="Highest Bid Auction" type="primary" style="position: absolute; top: -6px; right: 14px;"></el-badge>

          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import tokenTraits from '../assets/traits.json';
import ipfs from '../utils/ipfs';
import hicdex from '../utils/hicdex';

export default {
  name: 'Home',
  components: {
  },
  data: function() {
    return {
      traits: {},
      showFilters: true,
      search: "",
      sort: "rank asc",
      sortOpts: [
        { value: 'rank asc', label: 'Sort by Rank (asc)' },
        { value: 'rank desc', label: 'Sort by  Rank (desc)' },
        { value: 'price asc', label: 'Sort by Price (asc)' },
        { value: 'price desc', label: 'Sort by Price (desc)' },
      ],
      tokenFilters: {},
      activeFilters: [],
      displayedTokens: {},
      activeAsks: [],
      activeEnglishAuctions: [],
      activeDutchAuctions: [],
      objktCollection: process.env.VUE_APP_OBJKT_COLLECTION
    };
  },
  computed: {
    orderedTokens: function () {
      const sort = this.sort.split(" ");
      if (sort[0] === 'price') {
        return _.orderBy(tokenTraits, ['isForSale', sort[0]], ['desc', sort[1]]);
      }
      return _.orderBy(tokenTraits, sort[0], sort[1]);
    }
  },
  created() {
    this.displayedTokens = Object.values(this.orderedTokens).slice(0, 60);

    let numAttributes = {};

    for (const attributes of Object.values(tokenTraits)) {
      for (const attribute of attributes.attributes) {
        if (!Object.prototype.hasOwnProperty.call(this.traits, attribute.name)) {
          this.traits[attribute.name] = {};
          Vue.set(this.tokenFilters, attribute.name, []);
        }
        if (!Object.prototype.hasOwnProperty.call(this.traits[attribute.name], attribute.value)) {
          this.traits[attribute.name][attribute.value] = 0;
        }
        this.traits[attribute.name][attribute.value]++;
      }

      if (!Object.prototype.hasOwnProperty.call(numAttributes, attributes.attributes.length)) {
        numAttributes[attributes.attributes.length] = 0;
      }
      numAttributes[attributes.attributes.length]++;
    }

    this.traits['# Traits'] = numAttributes;
    Vue.set(this.tokenFilters, '# Traits', []);

    Vue.set(this.tokenFilters, 'For Sale', []);


    // Add in missing attribute counts
    for (const [ key, attribute ] of Object.entries(this.traits)) {
      this.traits[key] = Object.fromEntries(Object.entries(this.traits[key]).sort());
      const totalCount = Object.values(attribute).reduce((a, b) => a + b, 0);
      if (totalCount < 3000) {
        this.traits[key]['<None>'] = 3000 - totalCount;
      }
    }

    if (this.$route.query.f || this.$route.query.s) {
      if (this.$route.query.f) {
        const queryFilters = JSON.parse(atob(this.$route.query.f));
        for (const [category, values] of Object.entries(queryFilters)) {
          this.tokenFilters[category] = values;
          this.activeFilters.push(category);
        }
      }

      if (this.$route.query.s) {
        this.sort = atob(this.$route.query.s);
      }

      this.filterTokens();
    }
  },
  async mounted() {
    const vm = this;
    Promise.all([ hicdex.getActiveAsks(), hicdex.getActiveEnglishAuctions(), hicdex.getActiveDutchAuctions() ])
      .then(res => {
        vm.activeAsks = res[0];
        vm.activeEnglishAuctions = res[1];
        vm.activeDutchAuctions = res[2];

        for (const id in tokenTraits) {
          tokenTraits[id].price = undefined;
          tokenTraits[id].isForSale = false;
          const ask = vm.getFixedPrice(id);
          if (ask) {
            tokenTraits[id].price = ask.price;
            tokenTraits[id].isForSale = true;
          } else {
            const auction = vm.getEnglishAuction(id);
            if (auction) {
              tokenTraits[id].isForSale = true;
              if (auction.bids[0].amount > 0) {
                tokenTraits[id].price = auction.bids[0].amount;
              } else {
                tokenTraits[id].price = auction.reserve;
              }
            }
          }
        }

        if (vm.activeFilters.includes('For Sale')) {
          vm.filterTokens();
        }
      });
  },
  methods: {
    transformUri(uri) {
      return ipfs.transformUri(uri);
    },
    getFixedPrice: function (id) {
      return this.activeAsks.find(el => el.objkt_id == id)
    },
    getEnglishAuction: function (id) {
      return this.activeEnglishAuctions.find(el => el.objkt_id == id)
    },
    handleFilterChange() {
      let queryFilters = {};
      for (const [category, values] of Object.entries(this.tokenFilters)) {
        if (values.length) {
          queryFilters[category] = values;
        }
      }

      try {
        this.$router.replace({
          query: {
              f: btoa(JSON.stringify(queryFilters)),
              s: this.sort === 'rank asc' ? undefined : btoa(this.sort)
          }
        });
      } catch (e) {
        console.log(e);
      }

      this.filterTokens();
    },
    filterTokens() {
      let filtered = false;
      let tokens = this.orderedTokens;
      const vm = this;

      if (this.search) {
        filtered = true;
        tokens = tokens.filter((token) => token.id == parseInt(vm.search));
      }

      for (const [category, values] of Object.entries(this.tokenFilters)) {
        if (values.length) {
          filtered = true;
          tokens = tokens.filter((token) => {
            if (category === '# Traits') {
              return values.includes(token.attributes.length.toString());
            }

            if (category === 'For Sale') {
              if (values.includes('Fixed Price')) {
                if (vm.activeAsks.find(el => el.objkt_id == token.id)) {
                  return true;
                }
              }
              if (values.includes('English Auction')) {
                if (vm.activeEnglishAuctions.find(el => el.objkt_id == token.id)) {
                  return true;
                }
              }
              return false;
            }

            let hasCat = false;
            let catValue = undefined;

            for (const attribute of token.attributes) {
              if (attribute.name === category) {
                hasCat = true;
                catValue = attribute.value;
              }
            }

            if (values.includes('<None>') && !hasCat) {
              catValue = '<None>';
            }

            return values.includes(catValue);
          });
        }
      }

      this.displayedTokens = filtered ? Object.values(tokens) : Object.values(this.orderedTokens).slice(0, 100);
    }
  }
}
</script>
